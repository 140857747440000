<template>
<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                By Status
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar" />
    </div>
    <div class="kt-portlet__body">
        <div ref="chartDiv" class="chartDiv" />
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';

export default Vue.extend({
    name: 'BiliteracySealByStatus',
    components: {},
    props: {
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: { },
    mounted() {
        this.mountChart();
    },
    beforeDestroy() {
        if (this.root) this.root.dispose();
    },
    methods: {
        mountChart() {
            const v = this;
            const { loading } = this;
            if (loading) return;
            this.loading = true;
            const { chartDiv } = this.$refs;
            const root = am5.Root.new(chartDiv);

            root.setThemes([
                am5themesAnimated.new(root),
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
            // start and end angle must be set both for chart and series
            const chart = root.container.children.push(am5percent.PieChart.new(root, {
                startAngle: 180,
                endAngle: 360,
                layout: root.verticalLayout,
                innerRadius: am5.percent(50),
            }));

            // Create series
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
            // start and end angle must be set both for chart and series
            const series = chart.series.push(am5percent.PieSeries.new(root, {
                startAngle: 180,
                endAngle: 360,
                valueField: 'value',
                categoryField: 'category',
                alignLabels: false,
            }));

            // Add on click
            series.slices.template.events.on('click', (ev) => {
                v.onClick('ByStatus');
            });

            series.states.create('hidden', {
                startAngle: 180,
                endAngle: 180,
            });

            series.slices.template.setAll({
                cornerRadius: 5,
            });

            series.ticks.template.setAll({
                forceHidden: true,
            });

            // Set data
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
            series.data.setAll([
                { value: 60, category: 'Never ELLs Home Language: English' },
                { value: 14, category: 'Never ELLs Home Language: Other' },
                { value: 16, category: 'Ever ELLs' },
                { value: 10, category: 'ELLs' },
            ]);

            // Create legend
            // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
            const legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                marginTop: 15,
                marginBottom: 15,
            }));

            legend.data.setAll(series.dataItems);

            // Disabling labels and ticks
            series.labels.template.set('visible', false);
            series.ticks.template.set('visible', false);

            series.appear(1000, 100);
        },
    },
});
</script>

<style lang="scss">
.chartDiv {
    height: 300px;
    width: 100%;
}
</style>
