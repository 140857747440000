<template>
<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                By Languages Awarded
                <small>Excluding Spanish</small>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar" />
    </div>
    <div class="kt-portlet__body">
        <div ref="chartDiv" class="chartDiv" />
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
// import * as am5percent from '@amcharts/amcharts5/percent';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';

export default Vue.extend({
    name: 'BiliteracySealByLanguage',
    components: {},
    props: {
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: { },
    mounted() {
        this.mountChart();
    },
    beforeDestroy() {
        if (this.root) this.root.dispose();
    },
    methods: {
        mountChart() {
            const v = this;
            const { loading } = this;
            if (loading) return;
            this.loading = true;
            const { chartDiv } = this.$refs;
            const root = am5.Root.new(chartDiv);
            root.setThemes([
                am5themesAnimated.new(root),
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            const chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
            }));

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
            cursor.lineY.set('visible', false);

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            const xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
            });

            const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: 'country',
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            }));

            xRenderer.grid.template.set('visible', false);

            const yRenderer = am5xy.AxisRendererY.new(root, {});
            const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                extraMax: 0.1,
                renderer: yRenderer,
            }));

            yRenderer.grid.template.setAll({
                strokeDasharray: [2, 2],
            });

            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            const series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: 'Series 1',
                xAxis,
                yAxis,
                valueYField: 'value',
                sequencedInterpolation: true,
                categoryXField: 'country',
                tooltip: am5.Tooltip.new(root, { dy: -25, labelText: '{valueY}' }),
            }));

            // Add on click
            series.columns.template.events.on('click', (ev) => {
                v.onClick('ByLanguage');
            });

            series.columns.template.setAll({
                cornerRadiusTL: 5,
                cornerRadiusTR: 5,
                strokeOpacity: 0,
            });

            series.columns.template.adapters.add('fill', (fill, target) => chart.get('colors').getIndex(series.columns.indexOf(target)));

            series.columns.template.adapters.add('stroke', (stroke, target) => chart.get('colors').getIndex(series.columns.indexOf(target)));

            const colors = chart.get('colors');

            const data = [/* {
                country: 'Spanish',
                value: 4874,
                icon: '🇪🇸',
                columnSettings: { fill: colors.next() },
            },  */{
                country: 'French',
                value: 906,
                icon: '🇫🇷',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'Italian',
                value: 543,
                icon: '🇮🇹',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'Mandarin',
                value: 290,
                icon: '🇨🇳',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'German',
                value: 121,
                icon: '🇩🇪',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'Latin',
                value: 214,
                icon: '🏛️',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'Bangladeshi',
                value: 53,
                icon: '🇮🇳',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'ASL',
                value: 57,
                icon: '🤟',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'Arabic',
                value: 56,
                icon: '🇸🇦',
                columnSettings: { fill: colors.next() },
            }, {
                country: 'Russian',
                value: 46,
                icon: '🇷🇺',
                columnSettings: { fill: colors.next() },
            }];

            series.bullets.push(() => am5.Bullet.new(root, {
                locationY: 1,
                sprite: am5.Label.new(root, {
                    text: '{icon}',
                    fill: root.interfaceColors.get('alternativeText'),
                    // centerY: 0,
                    centerX: am5.p50,
                    populateText: true,
                    fontSize: 20,
                    // width: 50,
                    // height: 50,
                    // centerX: am5.p50,
                    centerY: am5.p50,
                    // shadowColor: am5.color(0x000000),
                    // shadowBlur: 4,
                    // shadowOffsetX: 4,
                    // shadowOffsetY: 4,
                    // shadowOpacity: 0.6,
                }),
            }));

            xAxis.data.setAll(data);
            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            xAxis.hide();
            chart.appear(1000, 100);
        },
    },
});
</script>

<style lang="scss">
.chartDiv {
    height: 300px;
    width: 100%;
}
</style>
