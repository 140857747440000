<template>
<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Seal Earners
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar" />
    </div>
    <div class="kt-portlet__body">
        <div ref="chartDiv" class="chartDiv" />
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
// import * as am5percent from '@amcharts/amcharts5/percent';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';

export default Vue.extend({
    name: 'BiliteracySealBigFive',
    components: {},
    props: {
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        schoolYear() {
            return this.$store.state.biliteracySeal.schoolYear;
        },
    },
    watch: {
        schoolYear() {
            this.mountChart();
        },
    },
    mounted() {
        this.mountChart();
    },
    beforeDestroy() {
        if (this.root) this.root.dispose();
    },
    methods: {
        mountChart() {
            const v = this;
            const { loading } = this;
            if (loading) return;
            this.loading = true;
            const { chartDiv } = this.$refs;

            if (this.root) this.root.dispose();

            const root = am5.Root.new(chartDiv);
            root.setThemes([
                am5themesAnimated.new(root),
            ]);

            const allData = {
                2015: {
                    Buffalo: 0,
                    'New York City': 149,
                    Rochester: 0,
                    Syracuse: 0,
                    Yonkers: 0,
                },
                2016: {
                    Buffalo: 22,
                    'New York City': 291,
                    Rochester: 9,
                    Syracuse: 0,
                    Yonkers: 0,
                },
                2017: {
                    Buffalo: 47,
                    'New York City': 530,
                    Rochester: 5,
                    Syracuse: 4,
                    Yonkers: 36,
                },
                2018: {
                    Buffalo: 70,
                    'New York City': 695,
                    Rochester: 31,
                    Syracuse: 21,
                    Yonkers: 87,
                },
                2019: {
                    Buffalo: 79,
                    'New York City': 712,
                    Rochester: 27,
                    Syracuse: 34,
                    Yonkers: 145,
                },
                2020: {
                    Buffalo: 53,
                    'New York City': 706,
                    Rochester: 48,
                    Syracuse: 29,
                    Yonkers: 126,
                },
                2021: {
                    Buffalo: 91,
                    'New York City': 1164,
                    Rochester: 69,
                    Syracuse: 46,
                    Yonkers: 172,
                },
            };
            const { schoolYear } = this;
            Object.keys(allData).forEach((year) => {
                if (schoolYear < year) {
                    delete allData[year];
                }
            });

            // Create root element
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element

            root.numberFormatter.setAll({
                numberFormat: '#a',

                // Group only into M (millions), and B (billions)
                bigNumberPrefixes: [/*
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' },
                 */],

                // Do not use small number prefixes at all
                smallNumberPrefixes: [],
            });

            const stepDuration = 750;

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            const chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
            }));

            // We don't want zoom-out button to appear while animating, so we hide it at all
            chart.zoomOutButton.set('forceHidden', true);

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            const yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 20,
                inversed: true,
                minorGridEnabled: true,
            });
            // hide grid
            yRenderer.grid.template.set('visible', false);

            const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: 'network',
                renderer: yRenderer,
            }));

            const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                strictMinMax: true,
                extraMax: 0.1,
                renderer: am5xy.AxisRendererX.new(root, {}),
            }));

            xAxis.set('interpolationDuration', stepDuration / 10);
            xAxis.set('interpolationEasing', am5.ease.linear);

            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            const series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis,
                yAxis,
                valueXField: 'value',
                categoryYField: 'network',
            }));

            // Add on click
            series.columns.template.events.on('click', (ev) => {
                v.onClick('BigFive');
            });

            // Rounded corners for columns
            series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 });

            // Make each column to be of a different color
            series.columns.template.adapters.add('fill', (fill, target) => chart.get('colors').getIndex(series.columns.indexOf(target)));

            series.columns.template.adapters.add('stroke', (stroke, target) => chart.get('colors').getIndex(series.columns.indexOf(target)));

            // Add label bullet
            series.bullets.push(() => am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Label.new(root, {
                    text: "{valueXWorking.formatNumber('#.# a')}",
                    fill: root.interfaceColors.get('alternativeText'),
                    centerX: am5.p100,
                    centerY: am5.p50,
                    populateText: true,
                }),
            }));

            const label = chart.plotContainer.children.push(am5.Label.new(root, {
                text: '2015',
                fontSize: '8em',
                opacity: 0.2,
                x: am5.p100,
                y: am5.p100,
                centerY: am5.p100,
                centerX: am5.p100,
            }));

            // Get series item by category
            function getSeriesItem(category) {
                for (let i = 0; i < series.dataItems.length; i++) {
                    const dataItem = series.dataItems[i];
                    if (dataItem.get('categoryY') == category) {
                        return dataItem;
                    }
                }
            }

            // Axis sorting
            function sortCategoryAxis() {
                // sort by value
                series.dataItems.sort((x, y) => y.get('valueX') - x.get('valueX'));

                // go through each axis item
                am5.array.each(yAxis.dataItems, (dataItem) => {
                    // get corresponding series item
                    const seriesDataItem = getSeriesItem(dataItem.get('category'));

                    if (seriesDataItem) {
                        // get index of series data item
                        const index = series.dataItems.indexOf(seriesDataItem);
                        // calculate delta position
                        const deltaPosition = (index - dataItem.get('index', 0)) / series.dataItems.length;
                        // set index to be the same as series data item index
                        if (dataItem.get('index') != index) {
                            dataItem.set('index', index);
                            // set deltaPosition instanlty
                            dataItem.set('deltaPosition', -deltaPosition);
                            // animate delta position to 0
                            dataItem.animate({
                                key: 'deltaPosition',
                                to: 0,
                                duration: stepDuration / 2,
                                easing: am5.ease.out(am5.ease.cubic),
                            });
                        }
                    }
                });
                // sort axis items by index.
                // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
                yAxis.dataItems.sort((x, y) => x.get('index') - y.get('index'));
            }

            let year = 2015;

            const sortInterval = setInterval(() => {
                sortCategoryAxis();
            }, 100);

            // update data with values each 1.5 sec
            const interval = setInterval(() => {
                year += 1;
                if (year >= 2021) {
                    clearInterval(interval);
                    clearInterval(sortInterval);
                    this.loading = false;
                }
                updateData();
            }, stepDuration);

            function setInitialData() {
                const d = allData[year];
                Object.keys(d).forEach((n) => {
                    series.data.push({ network: n, value: d[n] });
                    yAxis.data.push({ network: n });
                });
            }

            function updateData() {
                let itemsWithNonZero = 0;

                if (allData[year]) {
                    label.set('text', year.toString());

                    am5.array.each(series.dataItems, (dataItem) => {
                        const category = dataItem.get('categoryY');
                        const value = allData[year][category];

                        if (value > 0) {
                            itemsWithNonZero += 1;
                        }

                        dataItem.animate({
                            key: 'valueX',
                            to: value,
                            duration: stepDuration,
                            easing: am5.ease.linear,
                        });
                        dataItem.animate({
                            key: 'valueXWorking',
                            to: value,
                            duration: stepDuration,
                            easing: am5.ease.linear,
                        });
                    });

                    yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length);
                }
            }

            setInitialData();
            setTimeout(() => {
                year += 1;
                updateData();
            }, 50);

            this.root = root;
            series.appear(1000);
            chart.appear(1000, 100);
        },
    },
});
</script>

<style lang="scss">
.chartDiv {
    height: 500px;
    width: 100%;
}
</style>
