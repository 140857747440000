<template>
<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                By Ethnicity
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar" />
    </div>
    <div class="kt-portlet__body">
        <div ref="chartDiv" class="chartDiv" />
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';

export default Vue.extend({
    name: 'BiliteracySealByEthnicity',
    components: {},
    props: {
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        schoolYear() {
            return this.$store.state.biliteracySeal.schoolYear;
        },
    },
    watch: {
        schoolYear() {
            this.mountChart();
        },
    },
    mounted() {
        this.mountChart();
    },
    beforeDestroy() {
        if (this.root) this.root.dispose();
    },
    methods: {
        mountChart() {
            const v = this;
            const { chartDiv } = this.$refs;
            if (this.root) this.root.dispose();
            const root = am5.Root.new(chartDiv);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
            const chart = root.container.children.push(am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
            }));

            // Create series
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
            const series = chart.series.push(am5percent.PieSeries.new(root, {
                valueField: 'value',
                categoryField: 'category',
                numberFormatter: am5.NumberFormatter.new(root, { numberFormat: '#' }),
            }));

            // Add on click
            series.slices.template.events.on('click', (ev) => {
                v.onClick('ByEthnicity');
            });

            series.labels.template.set('text', "{value.formatNumber('#,###')}");

            // Set data
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
            series.data.setAll([
                { value: randomIntFromInterval(300, 400), category: 'White' },
                { value: randomIntFromInterval(0, 50), category: 'American Indian or Alaska Native' },
                { value: randomIntFromInterval(75, 100), category: 'Asian or Native Hawaiian or Other Pacific Islander' },
                { value: randomIntFromInterval(75, 100), category: 'Black or African American' },
                { value: randomIntFromInterval(150, 200), category: 'Hispanic or Latino or of Spanish Origin' },
                { value: randomIntFromInterval(0, 50), category: 'Multiracial' },
            ]);

            // Create legend
            // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
            const legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                marginTop: 15,
                marginBottom: 15,
            }));

            legend.data.setAll(series.dataItems);

            // Disabling labels and ticks
            series.labels.template.set('visible', false);
            series.ticks.template.set('visible', false);

            root.numberFormatter.setAll({
                numberFormat: '#',
                numericFields: ['valueY'],
            });

            this.root = root;
            series.appear(1000, 100);
        },
    },
});

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

</script>

<style lang="scss">
.chartDiv {
    height: 300px;
    width: 100%;
}
</style>
